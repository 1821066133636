<template>
  <div class="flex">
    <Dropdown
      @change="setSelectedBalance"
      v-model="selectedBalance"
      :options="balanceOptions"
      modelValue="currency"
      optionValue="currency"
      class="w-72 h-10 items-center"
      :disabled="userIsPlaying"
    >
      <template #value="slotProps">
        <div v-if="slotProps.value">
          <span class="font-medium text-gray-400 mr-4">{{
            slotProps.value
          }}</span>
          <span>
            {{ getBalanceByCurrency(slotProps.value) }}
          </span>
          <span>{{ userIsPlaying ? ` (${$t('app.in-play')})` : '' }}</span>
        </div>
      </template>
      <template #option="slotProps">
        <div class="flex justify-between">
          <span class="font-medium text-gray-400">{{
            slotProps.option.currency
          }}</span>
          <div>
            <p>
              {{
                formatNumber(
                  user[slotProps.option.amount_key],
                  slotProps.option.decimals
                )
              }}
            </p>
          </div>
        </div>
      </template>
    </Dropdown>
    <span class="text-gray-400 ms-2 flex items-center">
      <Button
        v-tooltip.hover.top="$t('app.deposit_note')"
        tooltipEvent="hover"
        class="p-0 text-gray-400 border-black bg-black"
      >
        <Icon icon="fa-solid fa-info-circle"
      /></Button>
    </span>
    <Button
      class="h-10 p-2 px-5 rounded-lg border-slate-600 ms-4 hover:bg-slate-700"
      @click="toggleWallet"
    >
      <Icon icon="fa-solid fa-wallet" class="mr-2" />
      {{ $t('withdrawals.wallet') }}
    </Button>
  </div>
</template>
<script setup>
import { useWebsiteInfoStore } from '@/stores/website';
import { useUserStore } from '@/stores/user';

const websiteInfoStore = useWebsiteInfoStore();
const userStore = useUserStore();

const website = computed(() => websiteInfoStore.website);
const selectedBalance = ref(website.value.currency);
const user = computed(() => userStore.user);

const userIsPlaying = computed(() => userStore.inPlay);

watch(userIsPlaying, (newValue) => {
  if (newValue) {
    selectedBalance.value = 'TOKENS';
  }
});

const balanceOptions = ref([
  {
    currency: website.value.currency,
    amount_key: 'total_coins',
    decimals: calculatePrecisionByExchange(
      website.value.exchange_rate,
      user.value.total_coins
    ),
  },
  { currency: 'TOKENS', amount_key: 'total_tokens', decimals: 2 },
]);

const setSelectedBalance = (bal) => {
  const cookie = useCookie('display_balance');
  cookie.value = bal.value;
};

// Recalculate decimals when user.total_coins changes
watch(
  () => user.value.total_coins,
  () => {
    const newDecimals = calculatePrecisionByExchange(
      website.value.exchange_rate,
      user.value.total_coins
    );
    balanceOptions.value[0].decimals = newDecimals;
  }
);

onMounted(() => {
  const cookie = useCookie('display_balance').value;

  if (cookie) {
    selectedBalance.value = cookie;
  }
  if (userIsPlaying.value) {
    selectedBalance.value = 'TOKENS';
  }
});

const getBalanceByCurrency = (cur) => {
  const bal = balanceOptions.value.find((bal) => bal.currency === cur);
  return bal ? formatNumber(user.value[bal.amount_key], bal.decimals) : '';
};

const toggleWallet = () => {
  userStore.toggleWalletTab('deposit');
};
</script>
